import Container from '@mui/material/Container';
import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import { ErrorBoundary } from 'Common/common-ui';
import { logScriptError } from 'Common/services/api';
import * as React from 'react';
import { Outlet, Route } from 'react-router-dom';
import ResourcePageWrapper from '../../ResourcePageWrapper';
import About from './components/About';
import ProvisionHeader from './components/ProvisionHeader';
import ProvisionDemo from './demo/ProvisionDemo';
import * as Paths from '@toolkit/router/paths';

const ProvisioningAutomation: React.FC = () => {
  const { featureFlags } = getBaseOutletContext();

  return (
    <Container disableGutters={true} maxWidth={false}>
      <ErrorBoundary onError={logScriptError}>
        <Outlet context={{ featureFlags }} />
      </ErrorBoundary>
    </Container>
  );
};

export default ProvisioningAutomation;

export const ProvisioningAutomationRouter = (
  <Route element={<ProvisionHeader />}>
    <Route index element={<About />} />
    <Route path={'about'} index element={<About />} />
    <Route
      path={'demo'}
      element={
        <ResourcePageWrapper
          dataTestId="provision-tool-demo"
          pageHeaderPath={Paths.ProvisionTool}
          child={<ProvisionDemo />}
        />
      }
    />
  </Route>
);
