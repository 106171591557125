import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';

const VerifyEmailRequired: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <Grid item>
        <Box
          display={'flex'}
          justifyContent="center"
          flexDirection={'column'}
          textAlign={'center'}
          alignItems="center"
          maxWidth={580}
          padding={1}
        >
          <Typography variant="subtitle1" gutterBottom paragraph>
            Please complete email verification by clicking on the link sent to
            your email address and click
            <Link
              style={{ cursor: 'pointer' }}
              onClick={() => loginWithRedirect()}
              sx={{ pl: 0.5, pr: 0.5 }}
            >
              here
            </Link>
            to login.
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VerifyEmailRequired;
