import Container from '@mui/material/Container';
import { ErrorBoundary } from 'Common/common-ui';
import { logScriptError } from 'Common/services/api';
import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import Dashboard from './AnalyticsLibrary';
import Dashboards, { dashboardLoader } from './dashboards/Dashboards';

const AnalyticsLibraryRouting: React.FC = () => {
  return (
    <Container maxWidth={false} sx={{ p: '0px !important', height: '100%' }}>
      <ErrorBoundary onError={logScriptError}>
        <Outlet />
      </ErrorBoundary>
    </Container>
  );
};

export default AnalyticsLibraryRouting;

export const AnalyticsLibraryRouter = (
  <Route>
    <Route
      index
      element={<Dashboards />}
      loader={dashboardLoader}
      shouldRevalidate={() => false}
    />
    <Route path={':id'} element={<Dashboard />} />
  </Route>
);
