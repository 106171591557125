import { SelectChangeEvent } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { CommonContext, NotificationStatus } from 'Common/common-ui';
import { SupportErrorMessage } from 'Common/components/AlertMessage';
import { FeedbackType } from 'Common/enums';
import { IError, SupportFeedbackForm } from 'Common/interfaces';
import { supportFeedback } from 'Common/services/api';
import { supportRequestType } from 'Common/util';
import * as React from 'react';
import { ChangeEvent } from 'react';
import { useMutation } from 'react-query';
import Error from 'Common/components/error';
import TealRadio from 'Common/components/TealRadio';
import TealLink from 'Common/components/TealLink';
import TealButton from 'Common/components/TealButton';
import InfoOutlined from '@mui/icons-material/InfoOutlined';

const defaultTouchFields: { [key: string]: boolean } = {
  name: false,
  email: false,
  message: false,
  feedbackOn: false,
};

const Support: React.FC = () => {
  const defaultSupportForm: SupportFeedbackForm = {
    name: '' as string,
    email: '' as string,
    feedbackOn: '' as string,
    requestType: FeedbackType.BugOrIssue as string,
    message: '' as string,
  };
  const {
    addNotification,
    state: { userDetails },
  } = React.useContext(CommonContext);
  const [open, setOpen] = React.useState<boolean>(false);
  const [formValues, setFormValues] =
    React.useState<SupportFeedbackForm>(defaultSupportForm);
  const [touchFields, setTouchFields] = React.useState<{
    [key: string]: boolean;
  }>(defaultTouchFields);

  const {
    mutateAsync: requestFeedback,
    isError: isSupportError,
    error: supportError,
  } = useMutation<undefined, IError, SupportFeedbackForm>(supportFeedback, {
    onSuccess: (data: { status: number; response: string }) => {
      resetAllFields();
      addNotification({
        message: data?.response,
        status: NotificationStatus.SUCCESS,
      });
    },
    onError: () => {
      addNotification({
        message: <SupportErrorMessage />,
        status: NotificationStatus.ERROR,
      });
    },
  });

  function resetAllFields(): void {
    setFormValues({
      ...formValues,
      feedbackOn: '',
      requestType: FeedbackType.BugOrIssue,
      message: '',
    });
    setTouchFields(defaultTouchFields);
  }

  React.useEffect(() => {
    if (userDetails) {
      setFormValues({
        ...formValues,
        name: userDetails.name,
        email: userDetails.email,
      });
    }
  }, [userDetails]);

  const changeFormFieldValue = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value, name }: { value: string; name: string } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  function calculateDescLength(): number {
    const descFieldLen: number =
      formValues.name.length +
      formValues.email.length +
      formValues.feedbackOn.length +
      formValues.message.length;
    return descFieldLen;
  }

  const changeFormSelectValue = (event: SelectChangeEvent): void => {
    const { value, name } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = (): boolean => {
    const supportObj: SupportFeedbackForm = { ...formValues };
    if (
      supportObj?.name?.trim()?.length > 0 &&
      validateEmail() &&
      supportObj?.feedbackOn?.trim()?.length > 0 &&
      supportObj?.message?.trim()?.length > 0 &&
      calculateDescLength() < 32500
    ) {
      return false;
    }
    return true;
  };

  function validateEmail(): boolean {
    const regEx = /\S+@\S+\.\S+/;
    return regEx.test(formValues.email);
  }

  const submitSupportForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await requestFeedback({
      ...formValues,
    });
  };

  function handleFieldsTouch(field: string): void {
    setTouchFields({
      ...touchFields,
      [field]: true,
    });
  }

  function errorValidation(field: string): boolean {
    return !formValues[field]?.trim()?.length && touchFields[field];
  }

  function supportField(
    field: string,
    id: string,
    label: string
  ): React.ReactNode {
    return (
      <>
        <FormLabel
          error={
            field === 'email'
              ? !validateEmail() && touchFields[field]
              : errorValidation(field)
          }
          component="label"
          required
        >
          {label}
        </FormLabel>
        <TextField
          id={id}
          name={field}
          onChange={changeFormFieldValue}
          variant={'outlined'}
          value={formValues[field]}
          sx={{ '& input': { p: '8px 10px' } }}
          error={
            field === 'email'
              ? !validateEmail() && touchFields[field]
              : errorValidation(field)
          }
          onBlur={() => handleFieldsTouch(field)}
          required
        />
      </>
    );
  }

  if (isSupportError) return <Error error={supportError} data={undefined} />;

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item md={6} lg={6} sx={{ pr: 3, pt: 2 }}>
          <Box component="div">
            <Typography variant="body1" component="p">
              If you have a general question, need more information about
              phData, or would like to contact sales, please use the phData
              <TealLink
                to={'https://www.phdata.io/contact-us/'}
                target="_blank"
                rel="noreferrer"
                sx={{ ml: 0.5, mr: 0.5 }}
              >
                contact us
              </TealLink>
              page.
            </Typography>
            <Typography variant="body1" mt={2}>
              For issues, bug reports, or feedback and feature requests, please
              fill out and submit this form. We would love to hear your
              thoughts, concerns, or problems with anything so that we can
              improve the phData Toolkit!
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          lg={6}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <form style={{ width: '100%' }} onSubmit={submitSupportForm}>
            <FormControl
              component="fieldset"
              sx={{ width: '100%', pl: 1, maxWidth: 550, '& label': { mt: 1 } }}
            >
              {supportField('name', 'name', 'Name')}
              {supportField('email', 'email', 'Email')}
              <FormLabel
                error={errorValidation('feedbackOn')}
                component="label"
                required
              >
                Feedback On:
              </FormLabel>
              <Select
                labelId="support-type-select-label"
                id="support-type-select"
                open={open}
                name="feedbackOn"
                value={formValues.feedbackOn}
                sx={{ '& .MuiSelect-select ': { p: '8px 10px' } }}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
                onChange={changeFormSelectValue}
                error={errorValidation('feedbackOn')}
                onBlur={() => handleFieldsTouch('feedbackOn')}
              >
                {supportRequestType.map((apptool, ind) => (
                  <MenuItem key={ind} value={apptool}>
                    {apptool}
                  </MenuItem>
                ))}
              </Select>
              {formValues.feedbackOn === 'Sales / Pricing' ? (
                <Alert
                  variant="outlined"
                  color="warning"
                  icon={<InfoOutlined />}
                  sx={{ my: 3 }}
                >
                  <Typography>
                    Please contact sales using the phData
                    <TealLink
                      to={'https://www.phdata.io/contact-us/'}
                      target="_blank"
                      rel="noreferrer"
                      sx={{ mx: 0.5 }}
                    >
                      contact us
                    </TealLink>
                    page.
                  </Typography>
                </Alert>
              ) : (
                <>
                  <FormLabel component="label" required>
                    Request type
                  </FormLabel>
                  <RadioGroup
                    row
                    name="requestType"
                    value={formValues.requestType}
                    onChange={changeFormFieldValue}
                  >
                    <FormControlLabel
                      value={FeedbackType.BugOrIssue}
                      control={<TealRadio />}
                      label="Bug/Issue"
                    />
                    <FormControlLabel
                      value={FeedbackType.Question}
                      control={<TealRadio />}
                      label="Question"
                    />
                    <FormControlLabel
                      value={FeedbackType.Feedback}
                      control={<TealRadio />}
                      label="Feedback"
                    />
                  </RadioGroup>
                  <FormLabel
                    error={errorValidation('message')}
                    component="label"
                    required
                  >
                    Message
                  </FormLabel>
                  <TextField
                    id="message"
                    name="message"
                    aria-label="describe"
                    style={{ width: '100%' }}
                    multiline
                    minRows={5}
                    placeholder="Describe here..."
                    onChange={changeFormFieldValue}
                    value={formValues.message}
                    maxRows={10}
                    error={errorValidation('message')}
                    onBlur={() => handleFieldsTouch('message')}
                    required
                  />
                  <TealButton
                    type="submit"
                    sx={{ mt: 2, maxWidth: 100, mb: 2, border: 0 }}
                    disabled={validateForm()}
                  >
                    Submit
                  </TealButton>
                </>
              )}
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Support;
