import React, { lazy } from 'react';
import Box from '@mui/material/Box';
import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import { ErrorBoundary } from 'Common/common-ui';
import { logScriptError } from 'Common/services/api';
import { Outlet, Route } from 'react-router-dom';

const DocumentWrapper = lazy(() => import('./DocumentWrapper'));
const NestedDocumentation = lazy(() => import('./NestedDocumentation'));

const Documentation: React.FC = () => {
  const { featureFlags } = getBaseOutletContext();

  return (
    <Box
      id="documnetation-box"
      overflow="auto"
      sx={{
        width: '100%',
        height: '100%',
        mx: 0,
        px: 0,
      }}
    >
      <ErrorBoundary onError={logScriptError}>
        <Outlet context={{ featureFlags }} />
      </ErrorBoundary>
    </Box>
  );
};

export default Documentation;

export const documentationRouter = (
  <Route>
    <Route index element={<DocumentWrapper />} />
    <Route path={':id/*'} element={<NestedDocumentation />} />
  </Route>
);
