import * as React from 'react';
import { ClickAwayListener, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { closeModal, CommonContext } from 'Common/common-ui';
import algoliasearch, { SearchClient } from 'algoliasearch/lite';
import { InstantSearch, SearchBox } from 'react-instantsearch';
import SearchContent from './SearchLayout';
import { setSearchText } from 'Common/common-ui/app-state/actions';
import { useDebouncedCallback } from 'use-debounce';
import { BrandColors } from 'Common/common-ui/common/theme';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import 'instantsearch.css/themes/satellite.css';

export default function SearchModal(): React.ReactElement {
  const {
    state: {
      searchText,
      algoliaCredentials: { applicationId, searchAPIKey, index: indexName },
    },
    dispatch,
  } = React.useContext(CommonContext);
  const searchClient: SearchClient = algoliasearch(applicationId, searchAPIKey);

  function handleClose(): void {
    dispatch(closeModal());
  }

  const onStateChange = useDebouncedCallback(({ uiState }) => {
    const query = uiState[indexName].query;
    const keyword = query?.trim();
    if (keyword?.length) {
      dispatch(setSearchText(keyword));
    }
  }, 1000);

  const searchFunction = (helper) => {
    const query: string = helper?.state?.query;
    if (query === '') {
      return;
    }
    helper.search();
  };

  const onResetCapture = () => {
    dispatch(setSearchText(''));
  };

  const onChangeCapture = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      onResetCapture();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box
        p={2}
        mt={1}
        overflow="hidden"
        sx={{
          '& .ais-SearchBox-form': { fontSize: 16, height: 50 },
          '& form.ais-SearchBox-form::before': {
            top: '38%',
            width: 25,
            height: 25,
            backgroundSize: '25px 25px',
            opacity: 0.75,
          },
          '& form.ais-SearchBox-form input': { pl: 6 },
          '& form.ais-SearchBox-form input:hover, & form.ais-SearchBox-form input:focus':
            { border: `2px solid ${BrandColors.Secondary}` },
          '& button.ais-SearchBox-reset': {
            width: 'auto',
            borderRadius: 'none',
          },
          width: {
            lg: 1100,
            md: 900,
            sm: 600,
          },
          height: {
            md: 680,
            sm: 'auto',
          },
        }}
      >
        <Tooltip title="Close">
          <IconButton
            size="small"
            sx={{ position: 'absolute', right: 7, top: 7 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <InstantSearch
          searchClient={searchClient}
          indexName={indexName}
          initialUiState={{
            [indexName]: {
              query: searchText,
            },
          }}
          onStateChange={onStateChange}
          searchFunction={searchFunction}
          insights
        >
          <SearchBox
            onResetCapture={onResetCapture}
            onChangeCapture={onChangeCapture}
            placeholder={'Search here ...'}
            resetIconComponent={({ classNames }) => (
              <Box
                color={BrandColors.Secondary}
                sx={{
                  ':hover': {
                    color: BrandColors.Primary,
                  },
                  backgroundColor: BrandColors.White,
                }}
                className={classNames.resetIcon}
              >
                Clear
              </Box>
            )}
            loadingIconComponent={() => <></>}
            autoFocus
          />
          {searchText && <SearchContent handleClose={handleClose} />}
        </InstantSearch>
      </Box>
    </ClickAwayListener>
  );
}
