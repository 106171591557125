import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import { ErrorBoundary } from 'Common/common-ui';
import { Actions } from 'Common/common-ui/app-state/actions';
import { CommonContext } from 'Common/common-ui/app-state/reducer';
import { logScriptError } from 'Common/services/api';
import { Outlet, useLocation } from 'react-router-dom';
import CollapseExpand from './CollapseExpand';
import NavLinkList from './NavLinkList';
import { Collapse } from '@mui/material';
import * as Paths from '@toolkit/router/paths';
import { BrandColors } from 'Common/common-ui/common/theme';

export const drawerWidth = 250;

enum DrawerType {
  EXPAND,
  COLLAPSE,
}

// This left nav is inspired by the Persistent drawer and Clipped drawer here:
// https://mui.com/components/drawers/
// https://github.com/mui-org/material-ui/blob/v5.2.4/docs/src/pages/components/drawers/PersistentDrawerLeft.tsx
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  width: '100%',
  overflow: 'hidden',
  overflowX: 'hidden',
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const LeftNav: React.FC = (): React.ReactElement => {
  const location = useLocation();
  const {
    state: { isLeftNavOpen: open, isDevEnv },
    dispatch,
  } = React.useContext(CommonContext);
  const { featureFlags, navOptions } = getBaseOutletContext();
  const matches: boolean = useMediaQuery(useTheme().breakpoints.down('sm'));

  React.useEffect(() => {
    handleToggle(!matches);
  }, [matches]);

  function handleToggle(toggle: boolean): void {
    dispatch({ type: Actions.TOGGLE_LEFT_NAV, payload: toggle });
  }

  function handleLeftNavCollapse(): void {
    if (matches) {
      handleToggle(false);
    }
  }

  return (
    // TODO: Update to use theme instead of CSS.
    <Box
      className="left-nav"
      display={'flex'}
      overflow={'hidden'}
      data-testid="left-nav-content"
    >
      {!open ? (
        <CollapseExpand
          handleToggle={(toggle) => handleToggle(toggle)}
          type={DrawerType.EXPAND}
          style={{ left: 0 }}
        />
      ) : (
        <CollapseExpand
          handleToggle={(toggle) => handleToggle(toggle)}
          type={DrawerType.COLLAPSE}
          style={{ left: drawerWidth }}
        />
      )}

      {navOptions && (
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Drawer
            sx={{
              '& .MuiDrawer-paper': { position: 'relative', top: 15 },
              flexShrink: 0,
            }}
            open={open}
            variant="persistent"
            anchor="left"
          >
            <Collapse orientation="horizontal" in={open}>
              <Box
                sx={{
                  height: `calc(100vh - ${isDevEnv ? '140px' : '90px'})`,
                  overflow: 'auto',
                }}
              >
                <NavLinkList
                  handleLeftNavCollapse={handleLeftNavCollapse}
                  routeLinks={navOptions}
                  featureFlags={featureFlags}
                />
              </Box>
            </Collapse>
          </Drawer>
          <Main
            id={'main-comp'}
            sx={{
              p: 0,
              m: 0,
              backgroundColor:
                location.pathname === Paths.AnalyticsLibrary
                  ? BrandColors.LightGray
                  : BrandColors.White,
              display: 'flex !important',
            }}
            open={open}
          >
            <Box
              component="div"
              height={`calc(100vh - ${isDevEnv ? '125px' : '75px'})`}
              sx={{
                width: '100% !important',
                p: 0,
                overflow: 'scroll',
                display: 'flex !important',
              }}
              id="box-inside-main"
            >
              <ErrorBoundary onError={logScriptError}>
                <Outlet context={{ featureFlags }} />
              </ErrorBoundary>
            </Box>
          </Main>
        </Box>
      )}
    </Box>
  );
};

export default LeftNav;
